import { useEffect } from "react";

export const useGoogleTagManager = (trackingId: string, MODE: string) => {
  useEffect(() => {
    // Do nothing if MODE is not production or staging
    if (!(MODE === "staging" || MODE === "production")) return;
    // Inject the Google Analytics script tag
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm/js?id=${trackingId}`;
    script.onload = () => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // @ts-ignore
        dataLayer.push(arguments);
      }
      // @ts-ignore
      gtag("js", new Date());
      // @ts-ignore
      gtag("config", trackingId);
    };
    document.body.appendChild(script);

    return () => {
      // Clean up the script tag if the component unmounts
      document.body.removeChild(script);
    };
  }, [trackingId, MODE]);
};
